@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600;700;800&display=swap');
@import '~antd/dist/antd.css';

@font-face {
    font-family: "Lineatura";
    src: url('assets/fonts/Lineatura.ttf') format("truetype");
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}

button, input {
    all: unset;
}

body {
    margin: 0;
    font-family: Raleway, sans-serif;
    background: #D2D2D2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 100vw;
}
